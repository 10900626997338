<template>

  <!-- 我的战队详情 -->
  <div class="show-wrap">
    <!--已入队开始-->
    <div class="team-header" v-if="team_template">
      <div class="team-header-cont">
        <div class="header-tit" v-if="userInfo.team_id == teamId">{{userInfo.team_id === 0 ? '' : '我的战队'}}</div>
        <div class="team-header-box" >
            <!--<div class="team-tip vertical-center">
                <img class="team-tip-img" src="~assets/images/index/team-tip.png" alt="">
                <div class="team-tip-text">温馨提示：每个用户终身有三次退出战队，请谨慎操作哦~</div>
            </div>-->
          <div class="team-info vertical-start">
              <img :src="teamInfo.cover_picture ? teamInfo.cover_picture : 'https://resources.suantan.net/mini-program/public/tack-team-avatar.png'" class="avatar-img"/>

            <div class="team-info-box">
              <div class="team-tit white-space">{{ teamInfo.name ? teamInfo.name : '酸谈社群' }}</div>
              <div class="people-num vertical-end-box">
                <img src="~assets/images/user/team-people.png"/>
                <span>{{ teamInfo.member_number + teamInfo.leader_number }}</span>
              </div>
              <div class="ranking-text">当前排名：<span class="theme-color">{{ teamInfo.rank && teamInfo.rank.rank}}</span><span v-if=" teamInfo.rank && teamInfo.rank.difference !== 0 && teamInfo.rank.rank !== 1">，与上一名相差<span class="theme-color">{{ teamInfo.rank && teamInfo.rank.difference }}</span>经验值</span></div>
              <div class="static-text center-text"  v-if="(userInfo.team_id == teamId && userInfo.is_team_master === 0) && (userInfo.status_team.operate_status === 5 || userInfo.status_team.operate_status === 2) ">待审核</div>
            </div>
          </div>

          <!--简介-->
          <div class="team-header-intro white-space-2" v-if="teamInfo.about" @click="showAbout(teamInfo.name,teamInfo.cover_picture,teamInfo.about)">
            {{ teamInfo.about ? '简介：'+ teamInfo.about :''}}
          </div>
        </div>
      </div>

      <div class="ranking-tit-box" :class="{'ranking-tit-top': teamInfo && teamInfo.about===null ,'ranking-tit-two': teamInfo.about && teamInfo.about.length < 22}">
        <div class="ranking-tit">队员</div>
      </div>
    </div>
    <!--已入队结束-->

    <!--排名列表开始-->
    <div class="team-ranking-wrap">
      <!--排名列表开始-->
      <div class="rank-item">
        <no-data v-if="membersInfo.length == 0" :bottomBg="true"></no-data>
          <van-list
              v-model:loading="loading"
              :finished="finished"
              :finished-text="membersInfo.length > 20 ? '没有更多了' : '' "
              @load="onLoad">
              <div v-for="(item,index) in membersInfo" :key="item.id">
                  <div class="rank-list vertical-center" :class="[userInfo.id === item.account.user_id ? 'rank-list-act' : '']">
                      <div class="user-avatar-box">
                          <!--              <img :src="item.wechat.avatar ? item.wechat.avatar : $confStatic.avatar" class="user-avatar"/>-->
                          <img class="user-avatar" :src="(item.wechat && item.wechat.avatar) ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" />
                      </div>
                      <div class="list-info">
                          <div class="user-info-box vertical-center">
                            <div class="name white-space">{{ (item.wechat && item.wechat.nickname) ? item.wechat.nickname : '酸谈社群' }}</div>
                            <div class="serial-number" v-if="item.number">编号:{{item.number}}</div>
                          </div>

                          <div class="info-box vertical-center">
                              <div class="leave-title">Lv{{ item.rule ? item.rule.detail.name : 0 }}</div>
                              <div class="experience-box vertical-center">
                                  <img src="~assets/images/index/task.png"/>
                                  <span class="experience-num">{{ $handle.tranNumber( item.account.experience, 3) }}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </van-list>

      </div>
      <!--排名列表结束-->
<!--        <BottomTip v-if="membersInfo.length > 6"></BottomTip>-->
    </div>
    <!--排名列表结束-->
    <div class="overlay-position">
        <!--战队简介开始-->
        <van-overlay :show="isShowAbout" z-index="9999">
            <div class="overlay-about-con align-center">
                <div class="overlay-about">
                    <img class="about-bg" src="~assets/images/user/about-bg.png" alt="">
                    <div class="overlay-about-head">
                        <div class="overlay-about-team">
                            <div class="team-title">{{ name ? name : '酸谈社群' }}</div>
                            <div class="about-team-img-bg">
                                <img class="about-team-img" :src="teamImger ? teamImger : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
                            </div>
                        </div>
                        <div class="overlay-about-title">{{ aboutText }}</div>
                    </div>
                    <div class="about-determine" @click="isShowAbout= false">确定</div>

                </div>
            </div>
        </van-overlay>
        <!--战队简介结束-->
    </div>
    <van-toast id="van-toast" />
  </div>

</template>

<script>

import BottomTip from "components/BottomTip";
import NoData from "components/NoData";

export default {
  name: "sign",
  data(){
    return{
      teamId: null,         //战队id
      teamInfo: {},         //战队信息
      membersInfo:[],        //战队用户信息
      outReason: '',          //退队原因
        team_template: false,
        isShowAbout: false,
        aboutText: '',
        name: '',
        teamImger: '',
        loading: '',
        finished: '',
        page: 1,
        pageSize: 50
    }
  },
  created() {
    // this.$user.getUserInfo();
    this.getTeamInfo()
    this.getMembers()
  },
  methods:{
    // members/{id}
    // 获取指定战队信息
    getTeamInfo: function(){
      let _this = this
      let url = _this.$api.Teams+`/${_this.$route.params.id}`
      _this.$http.get(url,true).then( res => {
        if(res.data.success){
          _this.teamInfo = res.data.data
            _this.team_template = true
        }
      } )
    },
    //获取战队队友信息
    getMembers: function(){
      let _this = this
      _this.teamId = _this.$route.params.id
      let url = _this.$api.Members+`/${this.$route.params.id}?page=${_this.page}&pageSize=${_this.pageSize}`
        _this.finished = true;
      _this.$http.get(url,true).then( res => {
        if(res.data.success){
          // _this.membersInfo = res.data.data
            this.loading = false;
            if(res.data.data.length){
                this.finished = false;
                _this.membersInfo =  _this.membersInfo.concat(res.data.data)
            }else {
                this.finished = true;
            }
        }
      } )
    },


      showAbout(name,teamImger,con){
          this.isShowAbout = true
          this.aboutText = con
          this.name = name
          this.teamImger = teamImger
      },
      onLoad(){
        this.page ++
          this.getMembers()
      }

  },
  watch:{
    userInfo(val, oldVal){
      return val;
    }
  },
  computed:{
    userInfo(){
      return this.$store.getters.userInfo;
    }
  },
    components:{
        BottomTip,
        NoData
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team/show.scss";
$r: 750/16/1rem;
//简介弹框开始
.overlay-about-con{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .overlay-about{
        width: 600/$r;
        background: #FFFFFF;
        border-radius: 20/$r;
        position: relative;
        .about-bg{
            width: 600/$r;
            height: 389/$r;
        }
        .overlay-about-head{
            position: relative;
            z-index: 1;
            width: 520/$r;
            min-height: 300/$r;
            margin: 0 auto;
            margin-top: -209/$r;
            background: #FFFFFF;
            border-radius: 10/$r;

            box-shadow: 0 6/$r 10px 0 rgba(221, 219, 219, 0.41);
            .overlay-about-team{
                position: absolute;
                top: -130/$r;
                left: 0;
                right: 0;
                margin: 0 auto;
                .team-title{
                    width: 100%;
                    margin-bottom: 26/$r;
                    font-size: 36/$r;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FDFAF9;
                    text-shadow: 0 9/$r 10/$r rgba(244, 110, 47, 0.48);
                    text-align: center;

                }
                .about-team-img-bg{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 116/$r;
                    height: 116/$r;
                    background: #FFFFFF;
                    box-shadow: 0 0 9/$r 0 rgba(244, 110, 47, 0.34);
                    border-radius: 50%;
                    margin: 0 auto;
                    .about-team-img{
                        width: 109/$r;
                        height: 109/$r;
                        border-radius: 50%;
                        display: block;
                    }
                  :deep(#avatar){
                        width: 100% !important;
                        height: 100% !important;
                        line-height: 109/$r !important;
                    }
                }
            }
            .overlay-about-title{
                padding: 74/$r 24/$r 22/$r 24/$r;
                font-size: 24/$r;
                font-family: PingFang SC;
                color: #333333;
                line-height: 36/$r;
            }

        }
        .about-determine{
            width: 500/$r;
            height: 77/$r;
            margin: 28/$r auto 28/$r;
            background: linear-gradient(117deg, #F28E26, #FD644F);
            box-shadow: 0 6/$r 27/$r 0 rgba(244, 110, 47, 0.27);
            border-radius: 39/$r;
            font-size: 28/$r;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 77/$r;
            text-align: center;
        }
    }
}
//简介弹框结束
</style>